import { useState, useEffect } from 'react';
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import TemplateCard from 'components/TemplateCard';
import { Grid, GridItem, Box, Text, Flex, Button } from '@chakra-ui/react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import LeftArrow from 'images/leftArrow';
import RightArrow from 'images/rightArrow';
import './styles.css';

export default function TemplatesList({
  textToFilter,
  lightMode,
  windowWidth,
}) {
  const { decisionsTemplates } = useCurrentDecisionsContext();
  const { dataOfTemplates } = decisionsTemplates;
  const [templatesAfterFilter, setTemplatesAfterFilter] =
    useState(dataOfTemplates);
  const [pageIndex, setPageIndex] = useState(0);
  const listOfPagesSizes = [6, 8, 10, 15, 20, 50];
  const [pageSize, setPageSize] = useState(listOfPagesSizes[0]);
  const [templatesToShowWithPagination, setTemplatesToShowWithPagination] =
    useState(dataOfTemplates);
  const { t } = useMarkdownTranslations();

  const getLowerNumber = (numberA, numberB) => {
    return numberA < numberB ? numberA : numberB;
  };

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (templatesAfterFilter.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = (pagesNumber) => {
    const buttonList = [];
    for (let i = 0; i < pagesNumber; i++) {
      buttonList.push(
        <Button
          className="button-change-page-template-list"
          color={pageIndex === i ? 'var(--MAIN-TEXT)' : 'var(--MAIN-GREY)'}
          bgColor={
            pageIndex === i ? 'var(--MAIN-GREY)' : 'var(--CLEAR-BACKGROUND)'
          }
          onClick={() => setPageIndex(i)}
          key={i}
        >
          {i + 1}
        </Button>,
      );
    }
    return <>{buttonList}</>;
  };

  useEffect(() => {
    const newTemplatesAfterFilter = dataOfTemplates?.filter((currentTemplate) =>
      currentTemplate.title.toLowerCase().includes(textToFilter.toLowerCase()),
    );
    setTemplatesAfterFilter(newTemplatesAfterFilter);
    setPageIndex(0);
    const positionOfFirstTemplateToShow = 0;
    let positionOfLastTemplateToShow = getLowerNumber(
      newTemplatesAfterFilter.length,
      (pageIndex + 1) * pageSize,
    );

    const newTemplatesToShow = newTemplatesAfterFilter.slice(
      positionOfFirstTemplateToShow,
      positionOfLastTemplateToShow,
    );

    setTemplatesToShowWithPagination(newTemplatesToShow);
  }, [textToFilter]);

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize]);

  useEffect(() => {
    const positionOfFirstTemplateToShow = pageIndex * pageSize;
    let positionOfLastTemplateToShow = getLowerNumber(
      templatesAfterFilter.length,
      (pageIndex + 1) * pageSize,
    );

    const newTemplatesToShow = templatesAfterFilter.slice(
      positionOfFirstTemplateToShow,
      positionOfLastTemplateToShow,
    );

    setTemplatesToShowWithPagination(newTemplatesToShow);
  }, [pageIndex]);

  return (
    <Box className="main-box-template-list">
      <Grid
        className={`grid-template-list ${
          lightMode
            ? 'grid-light-mode-template-list'
            : 'grid-dark-mode-template-list'
        }`}
      >
        {windowWidth <= 768
          ? templatesAfterFilter?.map((decisionTemplate, i) => (
              <GridItem key={i} className="grid-item-template-list">
                <TemplateCard decisionTemplate={decisionTemplate} />
              </GridItem>
            ))
          : templatesToShowWithPagination?.map((decisionTemplate, i) => (
              <GridItem key={i} className="grid-item-template-list">
                <TemplateCard decisionTemplate={decisionTemplate} />
              </GridItem>
            ))}
      </Grid>
      {windowWidth > 768 ? (
        <Flex
          className="outer-flex-template-list"
          role="region"
          aria-label={t('accessibility.pagination.arrows')}
        >
          <Flex className="column-flex-template-list">
            <Text className="showing-text-template-list">
              {t('pagination.participants', {
                from:
                  templatesAfterFilter.length > 0
                    ? pageIndex * pageSize + 1
                    : 0,
                to: getLowerNumber(
                  templatesAfterFilter.length,
                  (pageIndex + 1) * pageSize,
                ),
                total: templatesAfterFilter.length,
              })}
            </Text>
          </Flex>
          <Flex className="flex-pagination-template-list">
            <Flex
              onClick={previousPage}
              className={`flex-change-page-template-list`}
              opacity={pageIndex > 0 ? 1 : 0.84}
              _hover={{ cursor: pageIndex > 0 ? 'pointer' : 'not-allowed' }}
            >
              <Button
                className="button-arrow-page-template-list"
                cursor={pageIndex > 0 ? 'pointer' : 'not-allowed'}
              >
                <LeftArrow active={pageIndex > 0} />
              </Button>
            </Flex>
            <Flex className="flex-pagination-buttons-template-list">
              {getPaginationButtons(
                Math.ceil(templatesAfterFilter.length / pageSize),
              )}
            </Flex>
            <Flex
              onClick={nextPage}
              className={`flex-change-page-template-list ${
                pageIndex <
                Math.ceil(templatesAfterFilter.length / pageSize) - 1
                  ? 'flex-change-page-opacity-total-template-list'
                  : 'flex-change-page-opacity-partial-template-list'
              }`}
              opacity={
                pageIndex <
                Math.ceil(templatesAfterFilter.length / pageSize) - 1
                  ? 1
                  : 0.84
              }
              _hover={{
                cursor:
                  pageIndex <
                  Math.ceil(templatesAfterFilter.length / pageSize) - 1
                    ? 'pointer'
                    : 'not-allowed',
              }}
            >
              <Button
                className="button-arrow-page-template-list"
                cursor={
                  pageIndex < templatesAfterFilter.length - 1
                    ? 'pointer'
                    : 'not-allowed'
                }
              >
                <RightArrow
                  active={pageIndex < templatesAfterFilter.length - 1}
                />
              </Button>
            </Flex>
          </Flex>
          <Box></Box>
        </Flex>
      ) : (
        <Flex className="responsive-bottom-flex-templateList">
          <Flex
            onClick={previousPage}
            className={`flex-change-page-template-list`}
            opacity={pageIndex > 0 ? 1 : 0.84}
            _hover={{ cursor: pageIndex > 0 ? 'pointer' : 'not-allowed' }}
          >
            <Button
              className="button-arrow-page-template-list"
              cursor={pageIndex > 0 ? 'pointer' : 'not-allowed'}
            >
              <LeftArrow active={pageIndex > 0} />
            </Button>
          </Flex>
          <Flex className="flex-pagination-buttons-template-list">
            {getPaginationButtons(
              Math.ceil(templatesAfterFilter.length / pageSize),
            )}
          </Flex>
          <Flex
            onClick={nextPage}
            className={`flex-change-page-template-list ${
              pageIndex < Math.ceil(templatesAfterFilter.length / pageSize) - 1
                ? 'flex-change-page-opacity-total-template-list'
                : 'flex-change-page-opacity-partial-template-list'
            }`}
            opacity={
              pageIndex < Math.ceil(templatesAfterFilter.length / pageSize) - 1
                ? 1
                : 0.84
            }
            _hover={{
              cursor:
                pageIndex <
                Math.ceil(templatesAfterFilter.length / pageSize) - 1
                  ? 'pointer'
                  : 'not-allowed',
            }}
          >
            <Button
              className="button-arrow-page-template-list"
              cursor={
                pageIndex < templatesAfterFilter.length - 1
                  ? 'pointer'
                  : 'not-allowed'
              }
            >
              <RightArrow
                active={pageIndex < templatesAfterFilter.length - 1}
              />
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
