import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Flex,
  Tooltip,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import ModalDeleteMember from 'components/GroupAsAdmin/ModalDeleteMember';
import ModalEditMember from 'components/ModalEditMember';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';

const TableOfGroupMember = ({ search, sortBy }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredData, setFilteredData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 5;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { usersOfGroup } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    onOpen();
  };

  const handleDelete = (userId) => {
    // Implement delete functionality here
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    // Map usersOfGroup to the desired format
    const mappedData = usersOfGroup.map((user) => {
      const attributes = user.attributes;
      return {
        id: user.id,
        name: attributes.name || 'None',
        email: attributes.email || 'None',
        web3Address: attributes['public-address'] || 'None',
        role: attributes.roles.join(', '),
        decisions: attributes['decisions-count'] || 0,
        joinedOn: attributes['created-at']
          ? formatDate(attributes['created-at'])
          : 'None',
        lastVisit: attributes['last-visit-at']
          ? formatDate(attributes['last-visit-at'])
          : 'None',
      };
    });

    // Filter data based on search input
    const searchLower = search.toLowerCase();
    const filtered = mappedData.filter((row) => {
      return (
        row.name.toLowerCase().includes(searchLower) ||
        row.email.toLowerCase().includes(searchLower) ||
        row.web3Address.toLowerCase().includes(searchLower)
      );
    });

    // Sort data based on sortBy
    if (sortBy && sortBy !== 'none') {
      filtered.sort((a, b) => {
        switch (sortBy) {
          case 'name':
            return a.name.localeCompare(b.name);
          case 'email':
            return a.email.localeCompare(b.email);
          case 'web3Address':
            return a.web3Address.localeCompare(b.web3Address);
          case 'role':
            return a.role.localeCompare(b.role);
          case 'decisions':
            return b.decisions - a.decisions;
          case 'joinedOn':
            return new Date(b.joinedOn) - new Date(a.joinedOn);
          case 'lastVisit':
            return new Date(b.lastVisit) - new Date(a.lastVisit);
          default:
            return 0;
        }
      });
    }

    setFilteredData(filtered);
    setPageIndex(0); // Reset page when filtering
  }, [usersOfGroup, search, sortBy]);

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (filteredData.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = () => {
    const pagesNumber = Math.ceil(filteredData.length / pageSize);
    const buttons = [];
    for (let i = 0; i < pagesNumber; i++) {
      buttons.push(
        <Button
          key={i}
          className="button-change-page-decision-copy-list"
          color={pageIndex === i ? 'var(--MAIN-TEXT)' : 'var(--MAIN-GREY)'}
          bgColor={
            pageIndex === i ? 'var(--MAIN-GREY)' : 'var(--CLEAR-BACKGROUND)'
          }
          onClick={() => setPageIndex(i)}
        >
          {i + 1}
        </Button>,
      );
    }
    return buttons;
  };

  const truncateString = (str) => {
    return str.length > 9 ? `${str.substring(0, 9)}...` : str;
  };

  const currentPageData = filteredData.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize,
  );

  return (
    <Box
      className="main-box-table-of-current-decision"
      style={{ padding: '0' }}
    >
      <Box style={{ padding: '0' }}>
        <Box
          background="var(--CLEAR-BACKGROUND)"
          borderRadius="18px"
          p={{ base: '16px', md: '24px' }}
        >
          <Box borderRadius="12px" boxShadow="md">
            <Table
              variant="simple"
              size="md"
              borderWidth="1px"
              borderColor="gray.100"
              width="100%"
              borderRadius="12px"
            >
              <Thead className="thead-table-of-current-decision">
                <Tr>
                  <Th className="th-table-of-current-decision">
                    {t('participant.name')}
                  </Th>
                  <Th className="th-table-of-current-decision">
                    {t('participant.email')}
                  </Th>
                  <Th className="th-table-of-current-decision">
                    {t('participant.web3_address')}
                  </Th>
                  <Th className="th-table-of-current-decision">
                    {' '}
                    {t('participant.role.singular')}
                  </Th>
                  <Th className="th-table-of-current-decision">
                    {t('btn.decisions')}
                  </Th>
                  <Th className="th-table-of-current-decision">
                    {t('group.joined')}
                  </Th>
                  <Th className="th-table-of-current-decision">
                    {t('group.last_visit')}
                  </Th>
                  <Th className="th-table-of-current-decision"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentPageData.map((row) => (
                  <Tr key={row.id}>
                    <Td className="td-table-of-current-decision">{row.name}</Td>
                    <Td className="td-table-of-current-decision">
                      {row.email !== 'None' ? (
                        <Tooltip label={row.email} placement="top">
                          <Flex align="center" justify="space-between">
                            {truncateString(row.email)}
                            <IconButton
                              aria-label="Copy email"
                              icon={<CopyIcon />}
                              size="sm"
                              variant="ghost"
                              onClick={() =>
                                navigator.clipboard.writeText(row.email)
                              }
                            />
                          </Flex>
                        </Tooltip>
                      ) : (
                        'None'
                      )}
                    </Td>
                    <Td className="td-table-of-current-decision">
                      {row.web3Address !== 'None' ? (
                        <Tooltip label={row.web3Address} placement="top">
                          <Flex align="center" justify="space-between">
                            {truncateString(row.web3Address)}
                            <IconButton
                              aria-label="Copy web3 address"
                              icon={<CopyIcon />}
                              size="sm"
                              variant="ghost"
                              onClick={() =>
                                navigator.clipboard.writeText(row.web3Address)
                              }
                            />
                          </Flex>
                        </Tooltip>
                      ) : (
                        'None'
                      )}
                    </Td>
                    <Td className="td-table-of-current-decision">{row.role}</Td>
                    <Td className="td-table-of-current-decision">
                      {row.decisions}
                    </Td>
                    <Td className="td-table-of-current-decision td-date-table-of-current-decision">
                      {row.joinedOn}
                    </Td>
                    <Td className="td-table-of-current-decision td-date-table-of-current-decision">
                      {row.lastVisit}
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                        >
                          {t('authentication.password.reset')}
                        </Button>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-eye" />
                        </Button>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                          onClick={() => handleEditClick(row)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pencil-alt" />
                        </Button>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                          onClick={() => handleDeleteClick(row)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            w={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box>
              <Flex w={'250px'} justify="center" mt={4}>
                <Text fontSize="sm" color="gray.600">
                  {t('pagination.members', {
                    from: pageIndex * pageSize + 1,
                    to: Math.min(
                      (pageIndex + 1) * pageSize,
                      filteredData.length,
                    ),

                    total: filteredData.length,
                  })}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex justify="center" mt={6} gap={2}>
                <Button
                  onClick={previousPage}
                  isDisabled={pageIndex === 0}
                  className="button-change-page-decision-copy-list"
                  bgColor={'var(--CLEAR-BACKGROUND)'}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                </Button>
                <Flex gap={2}>{getPaginationButtons()}</Flex>
                <Button
                  onClick={nextPage}
                  isDisabled={filteredData.length <= (pageIndex + 1) * pageSize}
                  className="button-change-page-decision-copy-list"
                  bgColor={'var(--CLEAR-BACKGROUND)'}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                </Button>
              </Flex>
            </Box>
            <Box w={'250px'}></Box>
          </Box>
        </Box>
      </Box>
      {selectedUser && (
        <ModalDeleteMember
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          user={selectedUser}
          onDelete={handleDelete}
          isInvitation={false}
        />
      )}
      <ModalEditMember isOpen={isOpen} onClose={onClose} user={selectedUser} />
    </Box>
  );
};

export default TableOfGroupMember;
