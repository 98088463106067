import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  FormControl,
  FormLabel,
  Box,
  Spinner,
} from '@chakra-ui/react';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import groupsApi from 'api/groups';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import ModalInviteUser from 'components/ModalInviteUser';
import SentEmailCheckBox from 'components/SendEmailCheckBox';
import { getErrorMessage } from 'utils';

export default function ModalInviteGroup({ isOpen, onClose, group }) {
  const { fetchInvitesOfGroup } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  // State for form inputs
  const [emailOrWallet, setEmailOrWallet] = useState('');
  const [linkDecision, setLinkDecision] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [role, setRole] = useState('');

  // State for upload participants tab
  const [linkDecisionUpdate, setLinkDecisionUpdate] = useState('');
  const [fileUpload, setFileUpload] = useState(null);

  const inviteUserToGroup = async () => {
    const groupInvite = {
      group_invite: emailOrWallet.includes('@')
        ? {
            name: firstName,
            email: emailOrWallet,
            roles: [role],
            send_email: sendEmail,
            decision_id: linkDecision,
          }
        : {
            name: firstName,
            public_address: emailOrWallet,
            roles: [role],
            send_email: sendEmail,
            decision_id: linkDecision,
          },
    };

    try {
      setIsLoading(true);
      await groupsApi.createInvitation(group.id, groupInvite);
      await fetchInvitesOfGroup(group.id);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      console.error('Error inviting user to group:', e);
      const error = e?.data?.errors[0]?.detail;
      if (error) {
        setErrorMessage(t(getErrorMessage(error)));
      }
    }
  };

  const handleClose = () => {
    // Reset the form when closing the modal
    onClose();
    setLinkDecisionUpdate('');
    setEmailOrWallet('');
    setSendEmail(false);
    setIsLoading(false);
    setLinkDecision('');
    setFileUpload(null);
    setFirstName('');
    setRole('');
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: '90%', sm: '80%', md: '70%', lg: '60%' }}
      >
        <ModalBody paddingY={4}>
          <Tabs isFitted variant="unstyled">
            <Flex justifyContent="center" mb="1em">
              <TabList maxWidth="500px" width="100%">
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: '3px var(--MAIN-GREY) solid !important',
                    outline: 'none !important',
                  }}
                >
                  {t('group.tabs.invite_members')}
                </Tab>
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: '3px var(--MAIN-GREY) solid !important',
                    outline: 'none !important',
                  }}
                >
                  {t('group.tabs.upload_participants')}
                </Tab>
              </TabList>
            </Flex>
            <TabPanels>
              <TabPanel>
                <ModalInviteUser
                  setEmailOrWallet={setEmailOrWallet}
                  setLinkDecision={setLinkDecision}
                  emailOrWallet={emailOrWallet}
                  linkDecision={linkDecision}
                  setSendEmail={setSendEmail}
                  setFirstName={setFirstName}
                  errorMessage={errorMessage}
                  firstName={firstName}
                  setRole={setRole}
                  role={role}
                />
              </TabPanel>
              <TabPanel>
                <Flex
                  mb={4}
                  justifyContent="center"
                  alignItems="center"
                  borderTop="2px var(--INPUT-BACKGROUND) solid"
                  pt={8}
                >
                  <FormControl gridColumn={{ base: 'span 1', md: 'span 1' }}>
                    <Box display="flex" justifyContent="space-between">
                      <FormLabel
                        htmlFor="file-upload"
                        className="input-label-texts"
                      >
                        {t('btn.upload_file')}
                      </FormLabel>
                    </Box>
                    <Flex alignItems="center" gap={2}>
                      <Input
                        className="input-modal-invite-participants"
                        type="file"
                        variant="outline"
                        onChange={(e) => setFileUpload(e.target.files[0])}
                        sx={{
                          '::-webkit-file-upload-button': {
                            display: 'none',
                            marginTop: '-8px !important',
                          },
                          height: '50px',
                          padding: '0.5em',
                        }}
                      />
                      <Button
                        className="submit-button-modal-invite-participants"
                        onClick={() => {}}
                      >
                        {t('btn.upload_file')}
                      </Button>
                    </Flex>
                  </FormControl>
                </Flex>
                <FormControl gridColumn={{ base: 'span 1', md: 'span 1' }}>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel
                      htmlFor="link-decision-update"
                      className="input-label-texts"
                    >
                      {t('decision.link')}
                    </FormLabel>
                  </Box>
                  <Flex alignItems="center" gap={2}>
                    <Input
                      id="link-decision-update"
                      className="input-modal-invite-participants"
                      value={linkDecisionUpdate}
                      onChange={(e) => setLinkDecisionUpdate(e.target.value)}
                    />
                  </Flex>
                </FormControl>
                <SentEmailCheckBox setSendEmail={setSendEmail} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button className="first-button-modal-invite" onClick={handleClose}>
              {t('btn.go_back')}
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={() => inviteUserToGroup()}
            >
              {isLoading ? <Spinner /> : t('btn.confirm')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
