import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import logo from '../../../assets/images/polity_dark_mode2.png';
import groupsApi from 'api/groups';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';

const ModalDeleteMember = ({ isOpen, onClose, user, isInvitation }) => {
  const { fetchInvitesOfGroup, fetchUsersOfGroup, currentGroup } =
    useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  const handleDelete = async () => {
    try {
      if (isInvitation) {
        await groupsApi.deleteInvitationFromGroup(user.id);
        await fetchInvitesOfGroup(currentGroup.id);
      } else {
        await groupsApi.deleteUserFromGroup(user.id);
        await fetchUsersOfGroup(currentGroup.id);
      }
      onClose();
    } catch (error) {
      //todo: handle error
    }
  };
  return (
    <Modal padding={'0px'} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="18px" p={4}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          borderBottom="1px"
          borderColor="gray.300"
        >
          <img src={logo} alt="logo" width={126} height={23} />
          <Text fontSize="lg" fontWeight="bold" mb={0}>
            {t('group.delete.are_you_sure')}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {t('group.delete.click_below')}
          </Text>
        </Box>

        <ModalBody>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.name')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user.name}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.email')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user.email}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.wallet')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user.web3Address}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.role')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user.role}
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button
            onClick={onClose}
            width="70%"
            border="1px solid var(--MAIN-GREY)"
            backgroundColor="var(--CLEAR-BACKGROUND)"
            className="secondary-button"
          >
            {t('btn.go_back')}
          </Button>
          <Button className="danger-button" onClick={handleDelete} width="30%">
            {t('btn.i_am_sure')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDeleteMember;
