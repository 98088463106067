import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Tooltip,
  Box,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';

export default function ModalEditMember({ isOpen, onClose, user }) {
  const { t } = useMarkdownTranslations();
  const [selectedOption, setSelectedOption] = useState('');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: '30%', sm: '30%', md: '30%', lg: '30%' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          borderBottom="1px"
          borderColor="gray.300"
          p={4}
        >
          <Text fontSize="lg" fontWeight="bold" mb={0}>
            {t('group.edit_member')}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {t('group.edit_member_info')}
          </Text>
        </Box>

        <ModalBody>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.name')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user?.name}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.email')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user?.email}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.wallet')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user?.web3Address}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.role')}
            </Text>
            <Text mt={2} fontSize="sm">
              {user?.role}
            </Text>
          </Box>

          <Flex align="center" mt={1} mb={2}>
            <Text mt={1} mb={1} className="roles-modal-invite-participants">
              {t('participant.role.plural')}
            </Text>
            <Tooltip
              label={t('tooltip.roles')}
              aria-label={t('accessibility.information.roles')}
              placement="top-end"
              hasArrow
            >
              <Box
                as="span"
                ml={2}
                cursor="pointer"
                fontWeight="bold"
                color="white"
                _hover={{ color: 'white' }}
                background="black"
                borderRadius="full"
                height={6}
                width={6}
                textAlign="center"
                lineHeight="24px"
              >
                !
              </Box>
            </Tooltip>
          </Flex>
          <RadioGroup
            onChange={setSelectedOption}
            value={selectedOption}
            className="checkbox-text-modal-invite-participants"
          >
            <Flex direction="row" gap={4}>
              <Radio
                value="email"
                size="lg"
                className="checkbox-text-modal-invite-participants"
                sx={{
                  'span.chakra-radio__control': {
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'var(--INPUT-BACKGROUND)',
                    boxShadow: 'none',
                    _checked: {
                      bg: 'var(--INPUT-BACKGROUND)',
                      color: 'var(--MAIN-GREY)',
                    },
                  },
                  'span.chakra-radio__control[data-checked]': {
                    backgroundColor: 'var(--INPUT-BACKGROUND)',
                    color: 'var(--MAIN-GREY)',
                  },
                  'span.chakra-radio__control:focus': {
                    boxShadow: 'none',
                  },
                }}
              >
                <span className="checkbox-text-modal-invite-participants">
                  {t('account.admin')}
                </span>
              </Radio>

              <Radio
                value="link"
                size="lg"
                className="checkbox-text-modal-invite-participants"
                sx={{
                  'span.chakra-radio__control': {
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'var(--INPUT-BACKGROUND)',
                    boxShadow: 'none',
                    _checked: {
                      bg: 'var(--INPUT-BACKGROUND)',
                      color: 'var(--MAIN-GREY)',
                    },
                  },
                  'span.chakra-radio__control[data-checked]': {
                    backgroundColor: 'var(--INPUT-BACKGROUND)',
                    color: 'var(--MAIN-GREY)',
                  },
                  'span.chakra-radio__control:focus': {
                    boxShadow: 'none',
                  },
                }}
              >
                <span className="checkbox-text-modal-invite-participants">
                  {t('account.moderator')}
                </span>
              </Radio>

              <Radio
                value="wallet"
                size="lg"
                className="checkbox-text-modal-invite-participants"
                sx={{
                  'span.chakra-radio__control': {
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'var(--INPUT-BACKGROUND)',
                    boxShadow: 'none',
                    _checked: {
                      bg: 'var(--INPUT-BACKGROUND)',
                      color: 'var(--MAIN-GREY)',
                    },
                  },
                  'span.chakra-radio__control[data-checked]': {
                    backgroundColor: 'var(--INPUT-BACKGROUND)',
                    color: 'var(--MAIN-GREY)',
                  },
                  'span.chakra-radio__control:focus': {
                    boxShadow: 'none',
                  },
                }}
              >
                <span className="checkbox-text-modal-invite-participants">
                  {t('account.member')}
                </span>
              </Radio>
            </Flex>
          </RadioGroup>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button
              className="first-button-modal-invite"
              onClick={() => onClose()}
            >
              {t('btn.go_back')}
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={() => onClose()}
            >
              {t('btn.confirm')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
