import normalize from "json-api-normalizer";

export const mergeRolesByPosition = (response) => {
  const data = response.data || [];
  const included = response.included || [];

  // Ensure both arrays have the same length
  if (data.length !== included.length) {
    console.warn("Data and included arrays have different lengths");
    return included;
  }

  // Merge roles from data items into the corresponding included items by position
  const mergedData = included.map((item, index) => {
    const dataItem = data[index];
    if (dataItem && dataItem.attributes && dataItem.attributes.roles) {
      return {
        ...item,
        roles: dataItem.attributes.roles,
        ["is-owner"]: dataItem.attributes['is-owner']
      };
    }
    return item;
  });

  return mergedData;
};

export const normalizeApiResponse = (response) => {
  return normalize(response);
};

export const getErrorMessage = (errorCode) => {
  const errors = {
    auth: {
      invalid_email_or_password: "errors.auth.invalid",
      login_not_found: "errors.auth.not_found",
      reset_failed: "errors.auth.reset_failed",
      unexpected_error: "errors.auth.unexpected_error",
    },
    authentication: {
      web3: {
        invalid_address: "errors.authentication.web3.invalid_address",
        invalid_nonce: "errors.authentication.web3.invalid_nonce",
        invalid_signature: "errors.authentication.web3.invalid_signature",
        invite_not_found: "errors.invite_not_found",
        invite_used: "errors.invite_used",
        no_metamask: "errors.authentication.web3.no_metamask",
        not_found: "errors.authentication.web3.not_found",
        rejected: "errors.authentication.web3.rejected",
        taken: "errors.authentication.web3.taken",
      },
    },
    code: {
      invite_not_found: "errors.invite_not_found",
      invite_used: "errors.invite_used",
    },
    invite: {
      invite_not_found: "errors.invite_not_found",
      invite_used: "errors.invite_used",
    },
    messages: {
      already_invited: "errors.messages.already_invited",
      already_in_group: "errors.messages.already_in_group",
      invalid_invite_prospect: "errors.messages.invalid_invite_prospect",
      accepted: "errors.accepted",
      blank: "errors.blank",
      inclusion: "errors.messages.inclusion",
      invalid: "errors.messages.invalid",
      no_html: "errors.messages.no_html",
      not_a_number: "errors.messages.not_a_number",
      not_an_integer: "errors.messages.not_an_integer",
      taken: "errors.messages.taken",
      confirmation: {
        match: "errors.messages.confirmation.match",
        presence: "errors.blank",
      },
      email: {
        invalid: "errors.messages.email.invalid",
        presence: "errors.blank",
      },
      empty: "errors.blank",
      exclusion: "is reserved",
      file: {
        file_type: "errors.file.format",
        image_format: "errors.file.format",
        size: "errors.file.size",
      },
      password: {
        format:
          "errors.messages.password.format",
        length_high: "errors.messages.password.length_high",
        length_low: "errors.messages.password.length_low",
        presence: "errors.blank",
      },
      terms: {
        accepted: "errors.accepted",
      },
      too_long: {
        one: "errors.messages.too_long.one",
        other: "errors.messages.too_long.other",
      },
      too_short: {
        one: "errors.messages.too_short.one",
        other: "errors.messages.too_short.other",
      },
      username: {
        length: "errors.messages.username.length",
        presence: "errors.blank",
      },
      wrong_length: {
        one: "errors.messages.wrong_length.one",
        other: "errors.messages.wrong_length.other",
      },
    },
  };

  const partsOfCode = errorCode.split(".").slice(1);
  let message = errors;

  try {
    for (const part of partsOfCode) {
      message = message[part];
    }

    if (message) return message;
  } catch (error) {
    return 'error';
  }
};

export const timeDifference = (timeString) => {
  const time = new Date(timeString);
  const now = new Date();
  const difference = time - now;

  const absDifference = Math.abs(difference);

  const days = Math.floor(absDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(absDifference / (1000 * 60 * 60)) % 24;
  const minutes = Math.floor(absDifference / (1000 * 60)) % 60;
  const seconds = Math.floor(absDifference / 1000) % 60;

  let result = "";
  if (difference > 0) {
    if (days > 0) {
      result = `${days} Day${days > 1 ? "s" : ""} Left`;
    } else if (hours > 0) {
      result = `${hours} Hour${hours > 1 ? "s" : ""} Left`;
    } else if (minutes > 0) {
      result = `${minutes} Minute${minutes > 1 ? "s" : ""} Left`;
    } else {
      result = `${seconds} Second${seconds > 1 ? "s" : ""} Left`;
    }
  } else {
    if (days > 0) {
      result = `${days} Day${days > 1 ? "s" : ""} Ago`;
    } else if (hours > 0) {
      result = `${hours} Hour${hours > 1 ? "s" : ""} Ago`;
    } else if (minutes > 0) {
      result = `${minutes} Minute${minutes > 1 ? "s" : ""} Ago`;
    } else {
      result = `${seconds} Second${seconds > 1 ? "s" : ""} Ago`;
    }
  }
  return result;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  function pad(number) {
    return number < 10 ? "0" + number : number;
  }

  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = pad(date.getUTCMinutes());

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${ampm} UTC`;

  return formattedDate;
};
