import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Circle,
  Text,
  Box,
  Link,
  Spinner,
} from '@chakra-ui/react';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import groupsApi from 'api/groups';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { Separator } from 'components/Separator';
import notify from 'common/utils/notify';

export default function AcceptInvitationModal({ isOpen, onClose, group }) {
  const { t } = useMarkdownTranslations();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const { fetchGroupsAndInvitations } = useCurrentGroupsContext();

  const acceptInvitation = async (groupId) => {
    try {
      setIsloading(true);
      await groupsApi.acceptGroupInvite(groupId);
      await fetchGroupsAndInvitations();
      setIsloading(false);
      notify(
        'success',
        t('group.accept.successfully', {
          groupTitle: group?.attributes?.title,
        }),
      );
      onClose();
    } catch (e) {
      setIsloading(false);
      const error = e?.data?.errors[0]?.title;
      setErrorMessage(error);
      notify('error', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay width="100%" height="100%">
        <ModalContent
          borderRadius="16px"
          p={4}
          maxWidth={{ base: '90%', sm: '50%', md: '60%', lg: '40%' }}
          width={{ base: '90%', sm: '50%', md: '60%', lg: '40%' }}
        >
          <ModalHeader pb={0}>
            <Text className="modal-header">{t('group.join_us')}</Text>
          </ModalHeader>
          <ModalBody pb={0}>
            <Flex
              textAlign="left"
              mb={2}
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
            >
              <Circle
                size="60px"
                className="group-identification-circle"
                color="white"
                mr={4}
              >
                {group?.attributes?.title?.charAt(0)?.toUpperCase()}
              </Circle>
              <Box>
                <Text className="group-card-header">
                  {group?.attributes?.title}
                </Text>
                {group?.attributes?.url && (
                  <Link href={group.attributes.url} className="group-card-link">
                    {group.attributes.url}
                  </Link>
                )}
              </Box>
            </Flex>
            <Text className="input-form-label">{group?.attributes?.info}</Text>
            <Separator />
          </ModalBody>
          <ModalFooter p={0}>
            <Flex wrap="wrap" gap={2} justifyContent="center" width="100%">
              <Button
                className="main-button width-full"
                flex="1"
                minWidth="max-content"
                onClick={onClose}
              >
                {t('btn.cancel')}
              </Button>
              <Button
                className="secondary-button width-full"
                flex="1"
                minWidth="max-content"
                onClick={() => acceptInvitation(group.id)}
              >
                {isLoading ? <Spinner /> : t('btn.accept_invitation')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
