import React, { useState } from 'react';
import {
  Button,
  Text,
  Box,
  FormControl,
  Input,
  InputGroup,
  Card,
  CardBody,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import userApi from 'api/user';
import { getErrorMessage } from 'utils';
import SuccessModal from 'components/SuccessModal';
import './styles.css';

export default function ChangePassword() {
  const { user: userData } = useCurrentUserContext();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerification, setNewPasswordVerification] = useState('');
  const { tMarkdown, t } = useMarkdownTranslations();
  const [errorWithCurrentPassword, setErrorWithCurrentPassword] =
    useState(false);
  const [errorWithNewPassword, setErrorWithNewPassword] = useState(false);
  const [
    errorWithNewPasswordVerification,
    setErrorWithNewPasswordVerification,
  ] = useState(false);
  const [errorWithCurrentPasswordText, setErrorWithCurrentPasswordText] =
    useState('');
  const [errorWithNewPasswordText, setErrorWithNewPasswordText] = useState('');
  const [
    errorWithNewPasswordVerificationText,
    setErrorWithNewPasswordVerificationText,
  ] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const editPassword = () => {
    let errorFound = false;

    setErrorWithCurrentPassword(false);
    setErrorWithNewPassword(false);
    setErrorWithNewPasswordVerification(false);
    setErrorWithCurrentPasswordText('');
    setErrorWithNewPasswordText('');
    setErrorWithNewPasswordVerificationText('');

    const newPasswordWithoutTrim = newPassword.trim();

    const userDataForAPI = {
      user: {
        email: userData.email,
        name: userData.name,
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPasswordVerification,
      },
    };

    if (!currentPassword) {
      setErrorWithCurrentPassword(true);
      setErrorWithCurrentPasswordText(t('errors.password.empty'));
      errorFound = true;
    }

    if (!newPassword) {
      setErrorWithNewPassword(true);
      setErrorWithNewPasswordText(t('errors.password.empty'));
      errorFound = true;
    }

    if (newPassword.length !== newPasswordWithoutTrim.length) {
      setErrorWithNewPassword(true);
      setErrorWithNewPasswordText(t('errors.password.trim'));
      errorFound = true;
    }

    if (newPassword !== newPasswordVerification) {
      setErrorWithNewPasswordVerification(true);
      setErrorWithNewPasswordVerificationText(t('errors.password.mismatch'));
      errorFound = true;
    }

    if (errorFound) {
      return;
    }

    userApi
      .editProfile(userDataForAPI)
      .then(() => {
        setCurrentPassword('');
        setNewPassword('');
        setNewPasswordVerification('');
        onOpen();
      })
      .catch((e) => {
        e?.data?.errors?.forEach((currentError) => {
          switch (currentError.source.pointer) {
            case '/data/attributes/current-password':
              setErrorWithCurrentPassword(true);
              setErrorWithCurrentPasswordText(t('errors.password.wrong'));
              break;
            case '/data/attributes/password':
              setErrorWithNewPassword(true);
              setErrorWithNewPasswordText(
                t(getErrorMessage(currentError.detail)),
              );
              break;
            case '/data/attributes/password-confirmation':
              setErrorWithNewPasswordVerification(true);
              if (currentError.detail === 'errors.messages.confirmation') {
                setErrorWithNewPasswordVerificationText(
                  t('errors.password.mismatch'),
                );
              } else {
                setErrorWithNewPasswordVerificationText(
                  t(getErrorMessage(currentError.detail)),
                );
              }
              break;
            default:
              break;
          }
        });
      });
  };

  return (
    <>
      <SuccessModal
        isOpen={isOpen}
        onClose={onClose}
        text={t('authentication.password.changed')}
      />
      <Card
        className="card-change-password"
        role="form"
        aria-label={t('accessibility.authentication.change_password_form')}
      >
        <Text
          className="header-text-change-password"
          role="heading"
          aria-level="1"
        >
          {t('authentication.password.change')}
        </Text>
        <Text className="description-change-password">
          {tMarkdown('authentication.password.change_description_html')}
        </Text>
        <CardBody className="card-body-change-password">
          <Box className="box-change-password">
            <Text
              className="input-text-change-password"
              role="heading"
              aria-level="2"
            >
              {t('authentication.password.current')}
            </Text>
            <InputGroup>
              <Flex className="flex-column-change-password">
                <Input
                  className={
                    errorWithCurrentPassword
                      ? 'input-change-password-with-error'
                      : 'input-change-password'
                  }
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                {errorWithCurrentPassword && (
                  <Text className="error-text-change-password">
                    {errorWithCurrentPasswordText}
                  </Text>
                )}
              </Flex>
            </InputGroup>
          </Box>
          <FormControl className="first-form-control-change-password">
            <Text
              className="input-text-change-password"
              role="heading"
              aria-level="2"
            >
              {t('authentication.password.new')}
            </Text>
            <InputGroup>
              <Flex className="flex-column-change-password">
                <Input
                  className={
                    errorWithNewPassword
                      ? 'input-change-password-with-error'
                      : 'input-change-password'
                  }
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {errorWithNewPassword && (
                  <Text className="error-text-change-password">
                    {errorWithNewPasswordText}
                  </Text>
                )}
              </Flex>
            </InputGroup>
          </FormControl>
          <FormControl>
            <Text
              className="input-text-change-password"
              role="heading"
              aria-level="2"
            >
              {t('authentication.password.repeat')}
            </Text>
            <InputGroup>
              <Flex className="flex-column-change-password">
                <Input
                  className={
                    errorWithNewPasswordVerification
                      ? 'input-change-password-with-error'
                      : 'input-change-password'
                  }
                  type="password"
                  value={newPasswordVerification}
                  onChange={(e) => setNewPasswordVerification(e.target.value)}
                />
                {errorWithNewPasswordVerification && (
                  <Text className="error-text-change-password">
                    {errorWithNewPasswordVerificationText}
                  </Text>
                )}
              </Flex>
            </InputGroup>
          </FormControl>
        </CardBody>
        <Button className="button-change-password" onClick={editPassword}>
          {t('btn.save')}
        </Button>
      </Card>
    </>
  );
}
