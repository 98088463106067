import React, { useEffect, useRef, useState } from 'react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import politySideImage from 'assets/images/polity_side.png';
import ResourceNotFound from './Sections/ResourceNotFound';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import JoinWithAccount from './Sections/JoinWithAccount';
import down_arrow from 'assets/images/down_arrow.svg';
import arrow_up from 'assets/images/arrow_up.svg';
import { getErrorMessage } from 'utils';
import groupsApi from 'api/groups';
import './styles.css';
import {
  Flex,
  Button,
  Stack,
  Box,
  Image,
  Grid,
  Text,
  Link,
} from '@chakra-ui/react';
import { Separator } from 'components/Separator';
import GroupHeader from 'components/GroupHeader';
import JoinWithoutAccount from './Sections/JoinWithoutAccount';
import LoadingScreen from 'components/LoadingScreen';
import DecisionRedirect from './Sections/DecisionRedirect';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';

const JoinUs = () => {
  const { fetchGroupBySlug, currentGroup } = useCurrentGroupsContext();
  const { login } = useCurrentUserContext();
  const { t, tMarkdown } = useMarkdownTranslations();
  const { slug } = useParams();
  const textRef = useRef(null);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get('invite_token');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [displayGroupInfo, setDisplayGroupInfo] = useState(false);
  const [isResourceFound, setIsResourceFound] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [redirect, setRedirect] = useState('');
  const [decisionTitle, setDecisionTitle] = useState('');
  const [userName, setUserName] = useState('');

  const redirectUser = (data, included) => {
    const decision = included.find((e) => e.type === 'decision');
    if (decision) setDecisionTitle(decision.attributes.title);
    if (data.links['join-redirect']) setRedirect(data.links['join-redirect']);
    else {
      navigate(`/g/${currentGroup.attributes.slug}`, { replace: true });
    }
  };

  const verifyUserExists = async () => {
    try {
      setIsloading(true);
      const { data } = await groupsApi.verifyInviteUser(slug, inviteToken);
      if (data.relationships.user.data === null) {
        setUserName(data.attributes.name);
      } else {
        login();
        setUserName('');
      }
      setIsResourceFound(true);
      setIsloading(false);
    } catch (e) {
      setIsloading(false);
      const error = e?.data?.errors?.[0]?.detail;
      if (error) setErrorMessage(t(getErrorMessage(error)));
      else {
        setIsResourceFound(false);
      }
    }
  };

  useEffect(() => {
    if (currentGroup) {
      verifyUserExists();
    }
  }, [currentGroup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGroupBySlug(slug);
      } catch (e) {
        setIsloading(false);
        const error = e?.data?.errors?.[0]?.detail;
        if (error) setErrorMessage(t(getErrorMessage(error)));
        else {
          setIsResourceFound(false);
        }
      }
    };
    if (slug) fetchData();
  }, [slug]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleInfo = () => {
    setDisplayGroupInfo(!displayGroupInfo);
    setIsExpanded(false);
  };

  if (isLoading) return <LoadingScreen loading={isLoading} />;
  return (
    <Flex className="join-us-container" height="100vh">
      <Grid className="join-us-grid">
        {windowWidth > 768 && (
          <Image
            src={politySideImage}
            className="join-us-image"
            minHeight="100vh"
            maxHeight="100vh"
          />
        )}
        <Stack
          className="join-us-form"
          minHeight="100vh"
          maxHeight="100vh"
          spacing={0}
          justifyContent="center"
        >
          <h1 className="banner-bold">
            {!isResourceFound &&
              `${t('tooltip.invite')} ${t('pathname.not_found')}`}
            {isResourceFound && !!redirect && t('group.join_us.thanks')}
            {isResourceFound && !redirect && t('group.join_us')}
          </h1>
          {!redirect && isResourceFound && (
            <p className="subtitle-medium">
              {tMarkdown('group.join_us.description', {
                groupTitle: currentGroup?.attributes?.title,
              })}
            </p>
          )}
          {!!redirect && isResourceFound && (
            <p className="subtitle-medium">
              {tMarkdown('decision.start_voting', {
                decisionTitle,
                decisionLink: redirect,
              })}
            </p>
          )}
          {!isResourceFound && (
            <p className="subtitle-medium">{t('group.invite.not_found')}</p>
          )}
          {!redirect && (
            <>
              <Separator />
              <p className="input-form-label">
                {t('group.join_us.polity', {
                  groupTitle: currentGroup?.attributes?.title,
                })}
              </p>
            </>
          )}
          <Separator />
          {!isResourceFound && <ResourceNotFound />}
          {!userName && isResourceFound && !redirect && (
            <JoinWithAccount redirectUser={redirectUser} />
          )}
          {isResourceFound && !!redirect && (
            <DecisionRedirect redirect={redirect} />
          )}
          {!!userName && isResourceFound && !redirect && (
            <JoinWithoutAccount
              redirectUser={redirectUser}
              userName={userName}
              token={inviteToken}
            />
          )}
          {isResourceFound && !redirect && (
            <>
              <Separator />
              <Flex className="group-header-content">
                <Flex direction="column">
                  <GroupHeader />
                </Flex>
                {currentGroup?.attributes?.info && (
                  <Flex direction="column">
                    <Button
                      flex="1"
                      _hover={{
                        textDecoration: 'none',
                        background: 'transparent',
                      }}
                      textDecoration={'none'}
                      onClick={toggleInfo}
                      bg="transparent"
                      backgroundColor="transparent"
                    >
                      {displayGroupInfo ? (
                        <Image
                          color="var(--MAIN-GREY)"
                          src={down_arrow}
                          alt={t('btn.group.view_link')}
                        />
                      ) : (
                        <Image
                          color="var(--MAIN-GREY)"
                          src={arrow_up}
                          alt={t('btn.group.view_link')}
                        />
                      )}
                    </Button>
                  </Flex>
                )}
              </Flex>
              {displayGroupInfo && currentGroup?.attributes?.info && (
                <>
                  <Text className="group-card-header">{t('btn.info')}</Text>
                  <Box>
                    <Text
                      className="modal-header-secondary"
                      noOfLines={isExpanded ? null : 3}
                      textOverflow="ellipsis"
                      overflow="hidden"
                      ref={textRef}
                    >
                      {currentGroup.attributes.info}
                    </Text>
                    <Text
                      as="span"
                      cursor="pointer"
                      className="modal-header-secondary-bold"
                      onClick={toggleExpand}
                    >
                      {isExpanded ? t('btn.see_less') : t('btn.see_more')}
                    </Text>
                  </Box>
                </>
              )}
            </>
          )}
        </Stack>
      </Grid>
    </Flex>
  );
};

export default JoinUs;
