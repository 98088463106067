import { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Link,
  Box,
  FormControl,
  Center,
  Text,
  Spinner,
} from '@chakra-ui/react';
import userApi from 'api/auth';
import { getErrorMessage } from 'utils';
import { Separator } from './Separator';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';

const LoginFields = () => {
  const { t } = useMarkdownTranslations();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const { login } = useCurrentUserContext();
  const [email, setEmail] = useState('');

  const loginToApp = (email, password) => {
    setIsLoading(true);
    const data = { email, password };
    userApi
      .login({ user: data })
      .then(() => {
        login();
        setIsLoading(false);
        navigate('/user/groups/invited', { replace: true });
      })
      .catch((e) => {
        e?.data?.errors?.forEach((currentError) => {
          setErrorMessage(t(getErrorMessage(currentError.detail)));
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);

  return (
    <Box className="main-box-without-metamask">
      <Stack
        role="form"
        spacing={5}
        aria-label={t('accessibility.authentication.login_form')}
      >
        <FormControl>
          <Flex className="flex-password-without-metamask">
            <Box direction="column">
              <Text className="input-form-label ">
                {t('participant.email')}
              </Text>
            </Box>
            <Box direction="column" className="required-field">
              *
            </Box>
          </Flex>
          <Center>
            <InputGroup>
              <Input
                type="email"
                aria-labelledby={t('participant.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={'regular-input'}
              />
            </InputGroup>
          </Center>
        </FormControl>
        <FormControl>
          <Flex className="flex-password-without-metamask">
            <Box direction="column">
              <Text className="input-form-label ">
                {t('authentication.password')}
              </Text>
            </Box>
            <Box direction="column" className="required-field">
              *
            </Box>
          </Flex>
          <Center>
            <Flex
              className="flex-column-without-metamask"
              direction="column"
              w="100%"
            >
              <InputGroup>
                <Input
                  value={password}
                  aria-labelledby="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="regular-input"
                />
              </InputGroup>
              {errorMessage && (
                <Text className="error-text-without-metamask">
                  {errorMessage}
                </Text>
              )}
            </Flex>
          </Center>
        </FormControl>
        <Box>
          <Center>
            <Button
              className="accept-btn"
              onClick={() => loginToApp(email, password)}
            >
              {isLoading ? <Spinner /> : t('authentication.login')}
            </Button>
          </Center>
        </Box>
      </Stack>
      <Separator />
      <Flex className="flex-password-without-metamask">
        <Flex direction="column">
          <Box className="subtitle-medium margin-bottom">
            {t('group.invite.signup.register')}
          </Box>
          <Box className="subtitle-medium">
            {t('authentication.question.forgot_password')}
          </Box>
        </Flex>
        <Flex direction="column">
          <Link
            as={RouterLink}
            className="subtitle-bold margin-bottom"
            to="/signup"
          >
            {t('authentication.account.create')}
          </Link>
          <Link
            as={RouterLink}
            className="subtitle-bold"
            to="/recover-password"
          >
            {t('authentication.password.reset')}
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LoginFields;
