import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import groupsApi from 'api/groups';
import { Separator } from './Separator';
import notify from 'common/utils/notify';

export default function DeclineInvitationModal({ isOpen, onClose, group }) {
  const { t } = useMarkdownTranslations();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const { fetchGroupsAndInvitations } = useCurrentGroupsContext();

  const declineInvitation = async (groupId) => {
    try {
      setIsloading(true);
      await groupsApi.declineGroupInvite(groupId);
      await fetchGroupsAndInvitations();
      setIsloading(false);
      onClose();
      notify(
        'none',
        t('group.decline.successfully', {
          groupTitle: group?.attributes?.title,
        }),
      );
    } catch (e) {
      setIsloading(false);
      const error = e?.data?.errors[0]?.title;
      setErrorMessage(error);
      notify('error', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay width="100%" height="100%">
        <ModalContent
          borderRadius="16px"
          p={4}
          maxWidth={{ base: '90%', sm: '50%', md: '60%', lg: '40%' }}
          width={{ base: '90%', sm: '50%', md: '60%', lg: '40%' }}
        >
          <ModalHeader pb={0}>
            <Text className="modal-delete-header">
              {t('group.are_you_sure')}
            </Text>
          </ModalHeader>
          <ModalBody pb={0}>
            <Text className="input-form-label">
              {t('group.decline.invite', {
                groupTitle: group?.attributes?.title,
              })}
            </Text>
            <Separator />
          </ModalBody>
          <ModalFooter p={0}>
            <Flex wrap="wrap" gap={2} justifyContent="center" width="100%">
              <Button
                className="main-button width-full"
                flex="1"
                minWidth="max-content"
                onClick={onClose}
              >
                {t('btn.cancel')}
              </Button>
              <Button
                className="danger-button width-full"
                flex="1"
                minWidth="max-content"
                onClick={() => declineInvitation(group.id)}
              >
                {isLoading ? <Spinner /> : t('btn.decline.block')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
