import axios from 'axios';
import Papa from 'papaparse';

const fetchTranslations = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_STRIPE_BACKEND_URL}/translations.csv`,
            {
                responseType: 'blob',
            },
        );
        return await response.data.text();
    } catch (error) {
        console.error('Error fetching translations:', error);
        return null;
    }
}

const loadTranslations = async () => {
    let csvText;
    csvText = await fetchTranslations();
    if (!csvText) return;

    const parsedData = Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
    }).data;

    const translations = parsedData.reduce((acc, { key, locale, value }) => {
        if (!acc[locale]) {
            acc[locale] = { translation: {} };
        }
        acc[locale].translation[key] = value;
        return acc;
    }, {});

    return translations;
};

export default loadTranslations;