import Client from "./client";

const api = () => {
  return {
    getGroupsInvitations: () => {
      return Client.get("member/user/invites");
    },
    getAllGroups: () => {
      return Client.get("member/user/group_users");
    },
    getGroupBySlug: (groupSlug) => {
      return Client.get(`member/groups?group_slug=${groupSlug}`);
    },
    getHostedGroups: () => {
      return Client.get("member/user/group_users?role=host");
    },
    getJoinedGroups: () => {
      return Client.get("member/user/group_users?role=member");
    },
    createGroup: (data) => {
      return Client.post("member/user/groups", data);
    },
    getTranslations: () => {
      return Client.get("member/dev/i18n_reference");
    },
    deleteGroup: (groupId) => {
      return Client.delete(`member/groups/${groupId}`);
    },
    getGroupDecisions: (groupId) => {
      return Client.get(`member/groups/${groupId}/decisions`);
    },
    getGroupMembers: (groupId) => {
      return Client.get(`member/groups/${groupId}/members`);
    },
    getGroupMemberData: (groupId, userId) => {
      return Client.get(
        `member/groups/${groupId}/group_users?user_id=${userId}`,
      );
    },
    acceptGroupInvite: (groupId) => {
      return Client.get(`member/user/${groupId}/accept_invite`);
    },
    joinWithToken: (groupSlug, data) => {
      return Client.post(`member/groups/${groupSlug}/join_with_token`, data);
    },
    declineGroupInvite: (groupId) => {
      return Client.get(`member/user/${groupId}/decline_invite`);
    },
    createInvitation: (groupId, data) => {
      return Client.post(`member/groups/${groupId}/invites`, data);
    },
    leaveGroup: (groupId) => {
      return Client.get(`member/user/${groupId}/leave_group`);
    },
    editGroup: (groupId, data) => {
      return Client.patch(`member/groups/${groupId}/edit`, data);
    },
    getUsersOfGroup: (groupId) => {
      return Client.get(`member/groups/${groupId}/group_users`);
    },
    getInvitesOfGroup: (groupId) => {
      return Client.get(`member/groups/${groupId}/invites`);
    },
    editGroupUserRole: (groupUserId, data) => {
      return Client.patch(`member/group_users/${groupUserId}/edit`, data);
    },
    editGroupInvite: (inviteId, data) => {
      return Client.patch(`member/groups_invites/${inviteId}/edit`, data);
    },
    resendGroupInvite: (inviteId) => {
      return Client.get(`member/groups_invites/${inviteId}/resend`);
    },
    deleteUserFromGroup: (groupUserId) => {
      return Client.delete(`member/group_users/${groupUserId}`);
    },
    deleteInvitationFromGroup: (groupInviteId) => {
      return Client.delete(`member/groups_invites/${groupInviteId}`);
    },
    verifyInviteUser: (groupSlug, inviteToken) => {
      return Client.get(`member/groups/${groupSlug}/verify_token_with_login?invite_token=${inviteToken}`);
    },
  };
};

export default api();
