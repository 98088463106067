import { useState, useEffect } from 'react';
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import CopyDecisionCard from 'components/CopyDecisionCard';
import { Grid, GridItem, Box, Text, Flex, Button } from '@chakra-ui/react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import LeftArrow from 'images/leftArrow';
import RightArrow from 'images/rightArrow';
import './styles.css';

export default function DecisionCopyList({
  textToFilter,
  lightMode,
  windowWidth,
}) {
  const { tableDecisionsHostingData, setCreateDecisionData } =
    useCurrentDecisionsContext();

  const { dataOfTable } = tableDecisionsHostingData;

  const [decisionsAfterFilter, setDecisionsAfterFilter] = useState(dataOfTable);
  const [pageIndex, setPageIndex] = useState(0);
  const listOfPagesSizes = [6, 8, 10, 15, 20, 50];
  const [pageSize, setPageSize] = useState(listOfPagesSizes[0]);
  const [decisionsToShowWithPagination, setDecisionsToShowWithPagination] =
    useState(dataOfTable);
  const { tMarkdown, t } = useMarkdownTranslations();

  const getLowerNumber = (numberA, numberB) => {
    if (numberA < numberB) {
      return numberA;
    } else {
      return numberB;
    }
  };

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (decisionsAfterFilter.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = (pagesNumber) => {
    const paginationButtons = () => {
      const buttonList = [];
      for (let i = 0; i < pagesNumber; i++) {
        buttonList.push(
          <Button
            className="button-change-page-decision-copy-list"
            color={pageIndex === i ? 'var(--MAIN-TEXT)' : 'var(--MAIN-GREY)'}
            bgColor={
              pageIndex === i ? 'var(--MAIN-GREY)' : 'var(--CLEAR-BACKGROUND)'
            }
            onClick={() => setPageIndex(i)}
            key={i}
          >
            {i + 1}
          </Button>,
        );
      }
      return buttonList;
    };

    return <>{paginationButtons()}</>;
  };

  useEffect(() => {
    const newDecisionsAfterFilter = dataOfTable?.filter((currentDecision) =>
      currentDecision.title
        ?.toLowerCase()
        .includes(textToFilter?.toLowerCase()),
    );
    setDecisionsAfterFilter(newDecisionsAfterFilter);
    setPageIndex(0);
    const positionOfFirstDecisionToShow = 0;
    const positionOfLastDecisionToShow = getLowerNumber(
      newDecisionsAfterFilter.length,
      (pageIndex + 1) * pageSize,
    );

    const newTemplatesToShow = newDecisionsAfterFilter.slice(
      positionOfFirstDecisionToShow,
      positionOfLastDecisionToShow,
    );

    setDecisionsToShowWithPagination(newTemplatesToShow);
  }, [textToFilter]);

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize]);

  useEffect(() => {
    const positionOfFirstDecisionToShow = pageIndex * pageSize;
    const positionOfLastDecisionToShow = getLowerNumber(
      decisionsAfterFilter.length,
      (pageIndex + 1) * pageSize,
    );

    const newDecisionsToShow = decisionsAfterFilter.slice(
      positionOfFirstDecisionToShow,
      positionOfLastDecisionToShow,
    );

    setDecisionsToShowWithPagination(newDecisionsToShow);
  }, [pageIndex]);

  return (
    <Box className="main-box-decision-copy-list">
      <Grid
        className={`grid-decision-copy-list ${
          lightMode
            ? 'grid-light-mode-decision-copy-list'
            : 'grid-dark-mode-decision-copy-list'
        }`}
      >
        {windowWidth <= 768
          ? decisionsToShowWithPagination?.map((currentDecision, i) => (
              <GridItem key={i} className="grid-item-decision-copy-list">
                <CopyDecisionCard
                  currentDecision={currentDecision}
                  setCreateDecisionData={setCreateDecisionData}
                />
              </GridItem>
            ))
          : decisionsToShowWithPagination?.map((currentDecision, i) => (
              <GridItem key={i} className="grid-item-decision-copy-list">
                <CopyDecisionCard
                  currentDecision={currentDecision}
                  setCreateDecisionData={setCreateDecisionData}
                />
              </GridItem>
            ))}
      </Grid>
      {windowWidth > 768 ? (
        <Flex
          className="outer-flex-template-list"
          role="region"
          aria-label={t('accessibility.pagination.arrows')}
        >
          <Flex className="column-flex-template-list">
            <Text className="showing-text-template-list">
              {t('pagination.participants', {
                from:
                  decisionsAfterFilter.length > 0
                    ? pageIndex * pageSize + 1
                    : 0,
                to: getLowerNumber(
                  decisionsAfterFilter.length,
                  (pageIndex + 1) * pageSize,
                ),
                total: decisionsAfterFilter.length,
              })}
            </Text>
          </Flex>
          <Flex className="flex-pagination-template-list">
            <Flex
              onClick={() => previousPage()}
              className={`flex-change-page-template-list`}
              opacity={pageIndex > 0 ? 1 : 0.84}
              _hover={{ cursor: pageIndex > 0 ? 'pointer' : 'not-allowed' }}
            >
              <Button
                className="button-arrow-page-decision-copy-list"
                cursor={pageIndex > 0 ? 'pointer' : 'not-allowed'}
              >
                <LeftArrow active={pageIndex > 0} />
              </Button>
            </Flex>
            <Flex className="flex-pagination-buttons-template-list ">
              {getPaginationButtons(
                Math.ceil(decisionsAfterFilter.length / pageSize),
              )}
            </Flex>
            <Flex
              onClick={() => nextPage()}
              className={`flex-change-page-template-list ${
                pageIndex <
                Math.ceil(decisionsAfterFilter.length / pageSize) - 1
                  ? 'flex-change-page-opacity-total-template-list'
                  : 'flex-change-page-opacity-partial-template-list'
              }`}
              opacity={
                pageIndex <
                Math.ceil(decisionsAfterFilter.length / pageSize) - 1
                  ? 1
                  : 0.84
              }
              _hover={{
                cursor:
                  pageIndex <
                  Math.ceil(decisionsAfterFilter.length / pageSize) - 1
                    ? 'pointer'
                    : 'not-allowed',
              }}
            >
              <Button
                className="button-arrow-page-decision-copy-list"
                cursor={
                  pageIndex < decisionsAfterFilter.length - 1
                    ? 'pointer'
                    : 'not-allowed'
                }
              >
                <RightArrow
                  active={pageIndex < decisionsAfterFilter.length - 1}
                />
              </Button>
            </Flex>
          </Flex>
          <Box></Box>
        </Flex>
      ) : (
        <Flex className="responsive-bottom-flex-decision-copy-list">
          <Flex
            onClick={() => previousPage()}
            className={`flex-change-page-template-list`}
            opacity={pageIndex > 0 ? 1 : 0.84}
            _hover={{ cursor: pageIndex > 0 ? 'pointer' : 'not-allowed' }}
          >
            <Button
              className="button-arrow-page-decision-copy-list"
              cursor={pageIndex > 0 ? 'pointer' : 'not-allowed'}
            >
              <LeftArrow active={pageIndex > 0} />
            </Button>
          </Flex>
          <Flex className="flex-pagination-buttons-template-list ">
            {getPaginationButtons(
              Math.ceil(decisionsAfterFilter.length / pageSize),
            )}
          </Flex>
          <Flex
            onClick={() => nextPage()}
            className={`flex-change-page-template-list ${
              pageIndex < Math.ceil(decisionsAfterFilter.length / pageSize) - 1
                ? 'flex-change-page-opacity-total-template-list'
                : 'flex-change-page-opacity-partial-template-list'
            }`}
            opacity={
              pageIndex < Math.ceil(decisionsAfterFilter.length / pageSize) - 1
                ? 1
                : 0.84
            }
            _hover={{
              cursor:
                pageIndex <
                Math.ceil(decisionsAfterFilter.length / pageSize) - 1
                  ? 'pointer'
                  : 'not-allowed',
            }}
          >
            <Button
              className="button-arrow-page-decision-copy-list"
              cursor={
                pageIndex < decisionsAfterFilter.length - 1
                  ? 'pointer'
                  : 'not-allowed'
              }
            >
              <RightArrow
                active={pageIndex < decisionsAfterFilter.length - 1}
              />
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
