import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import HubspotForm from 'react-hubspot-form';
import './styles.css';

export default function HubspotModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useMarkdownTranslations();

  return (
    <Box className="main-box-hubspot-modal">
      <Button onClick={onOpen} className="button-styles-hubspot-modal">
        {t('btn.book_consultation')}
      </Button>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="modal-content-hubspot-modal">
          <HubspotForm
            portalId="6224498"
            formId="a14328b1-0fc3-40ad-b6dc-f0d4c59facb9"
            loading={<div>{t('loading')}...</div>}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
}
